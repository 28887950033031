import React from "react"

import About from "../components/home/about"

import {
  Layout,
  Container,
  SEO,
  Gradients,
  Subscription,
} from "../components/common"

export const ItemsContext = React.createContext({})

export default ({
  pageContext: {
    items,
    stats,
    allTimePopularItems,
    lastWeekPopularItems,
    lastMonthPopularItems,
    defaultGradient,
    footerGradient,
  },
}) => {
  const totalItems = items.length
  return (
    <ItemsContext.Provider value={totalItems}>
      <Layout
        title="Multicolor Gradients"
        description="Pure CSS Code, JPG Download, And Free!"
        defaultGradient={defaultGradient}
        footerGradient={footerGradient}
        stats={stats}
        totalItems={totalItems}
      >
        <Container>
          <SEO
            title="Multicolor CSS Gradients, JPG Downloads, 100% Free!"
            description="Use pure CSS gradient backgrounds for your next website or app, as a JPG image or CSS code, no attribute required!"
          />
          <Gradients
            items={items}
            allTimePopularItems={allTimePopularItems}
            lastWeekPopularItems={lastWeekPopularItems}
            lastMonthPopularItems={lastMonthPopularItems}
          />
          <About />
          <Subscription />
        </Container>
      </Layout>
    </ItemsContext.Provider>
  )
}
