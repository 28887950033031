import React from "react"

import { ItemsContext } from "../../templates/home"

import "./about.css"

const About = () => {
  return (
    <div className="about-main">
      <div className="about-item">
        <div className="about">
          <div className="about-head">
            <div className="about-preview about-preview-rectangle" />
            <ItemsContext.Consumer>
              {totalItems => <h2>{totalItems} Carefully Crafted Codiofuls</h2>}
            </ItemsContext.Consumer>
          </div>
          <p>
            Gradients are fun to do, but i always tried to make it visually
            appealing.
            <br /> Every gradient has been made with a care from the heart!
          </p>
        </div>
        <div className="about">
          <div className="about-head">
            <div className="about-preview about-preview-circle" />
            <h2>Aspect Ratio Free Backgrounds</h2>
          </div>
          <p>
            From square to ultrawide, you can use them at any ratio you want.
            <br />
            Just define the width and height and put the codes, simple!
          </p>
        </div>
        <div className="about">
          <div className="about-head">
            <div className="about-preview about-preview-triangle" />
            <h2>CSS Code And Downloadable Image</h2>
          </div>
          <p>
            What you see is what you get, all the CSS code can render JPG for
            the <br /> designers. Both images & codes are free, use however you
            want!
          </p>
        </div>
      </div>
    </div>
  )
}

export default About
